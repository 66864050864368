<template>
  <div class="bg-white padding content">
    <div class="margin-top flex justify-content-center content-table">
      <div id="printTest">
        <div style="font-size: 22px;padding-bottom:10px;font-weight:800;font-family:宋体; text-align:center">戴氏精品堂 业务凭证</div>
        <div class="flex justify-content-between padding-lr">
          <span>
            <span>学院姓名：杨某</span>
            <span class="margin-left-50">订单类型：报名</span>
            <span class="margin-left-50">经办日期：2021-03-06</span>
          </span>
          <span>订单号：56861</span>
        </div>

        <table class="margin-top-sm">
          <thead>
            <th>交易内容</th>
            <th>班级信息</th>
            <th>上课时间</th>
            <th>价格</th>
            <th>数量</th>
            <th>原价</th>
            <th>优惠</th>
            <th>应收小计</th>
          </thead>
          <tbody>
            <tr v-for="item in tableData" :key="item.id">
              <td>{{ item.date }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.address }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <thead>
            <th colspan="8">备注:</th>
          </thead>
          <thead>
            <th colspan="2">应收总计：￥5616.00</th>
            <th colspan="2">实收总计：￥5616.00</th>
            <th colspan="2">使用余额：￥0.00</th>
            <th colspan="2">欠费：￥0.00</th>
          </thead>
          <thead>
            <th colspan="8">收款方式: <span class="text-span">现金收款￥5616</span> </th>
          </thead>
          <thead>
            <th colspan="1">电话：</th>
            <th colspan="1">地址：</th>
            <th colspan="1">打印时间：</th>
            <th colspan="1">经办人：</th>
            <th colspan="2">经办签名：</th>
            <th colspan="2">客户签名：</th>
          </thead>
        </table>
        
        <div class="margin-top-sm">
          开课三次后不予办理退费，请您保管好您的收据，丢失不予办理。
        </div>
        
        <div class="margin-top flex justify-content-center">
          <el-row>
            <el-button type="primary">针式打印</el-button>
            <el-button type="primary">热敏打印</el-button>
            <el-button type="primary">打印听课证</el-button>
            <el-button @click="toPage()">查看订单</el-button>
            <el-button @click="toPage()">查看学员</el-button>
            <el-button @click="toPage('/workCenter/index')">返回办理中心</el-button>
            <el-button @click="toPage()">排课</el-button>
          </el-row>
        </div>
        
        <div class="margin-top flex justify-content-center">
          <div class="flex justify-content-center text-blue">
            <div class="flex aligin-items-center">
             <i class="el-icon-question"></i>
              <el-link type="primary">热敏打印机安装</el-link>
            </div>
            <div class="margin-left-50 flex aligin-items-center">
             <i class="el-icon-question"></i>
              <el-link type="primary">热敏打印机安装</el-link>
            </div>
            <div class="margin-left-50 flex aligin-items-center">
             <i class="el-icon-question"></i>
              <el-link type="primary">热敏打印机安装</el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      printDialogVisible: false,
      tableData: [
        {
          id: 1,
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          id: 2,
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          id: 3,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          id: 4,
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    toPage(src) {
      this.$router.push({
        path: src
      });
    },
  }
};
</script>

<style scoped>
#printTest {
  width: 1000px;
}
#printTest table {
  font-family: '宋体';
  border-collapse: collapse;
  width: 99.5%;
}
#printTest table thead th {
  height: 40px;
  font-size: 13px;
  /* width: 10px; */
  border: 1px solid black;
  text-align: left;
  padding-left: 10px;
}
#printTest table tbody tr {
  font-size: 13px;
  border: 1px solid black;
  height: 40px;
  padding-left: 10px;
}
#printTest table tbody td {
  font-size: 13px;
  border: 1px solid black;
  padding-left: 10px;
}
.text-span {
  font-family: '宋体';
  font-size: 13px;
  height: 40px;
}
</style>
